import { useStaticQuery, graphql } from "gatsby"

const ALL_EPRESCRIPTION_PRODUCTS = graphql`
  query MedicineSearchProducts {
    allProducts {
      nodes {
        brand
        molecule
        productTitle
        rxRequired
        rxLevel
        category
        classification
        itemCode
        priceList {
          price_list
          vatex_unit_price
          vat
        }
      }
    }
  }
`

export const useEprescriptionProducts = () => {
  const data = useStaticQuery(ALL_EPRESCRIPTION_PRODUCTS)

  return data
}
