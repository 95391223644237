import React, { Fragment, useContext, useEffect, useState } from "react"
import { Form, Formik } from "formik"
import { Link, navigate } from "gatsby"
import moment from "moment"

import Message from "elements/Message"
import { ProgramName } from "elements/Brand"
import ActionButtons from "elements/ActionButtons"
import PatientInformationSection from "./EprescriptionForm/PatientInformationSection"
import EprescriptionDetailsSection from "./EprescriptionForm/EprescriptionDetailsSection"

import { isFutureDate } from "../../services/date"
import { AppContext } from "../../context/AppContext"
import { getAuthUser } from "../Auth/services/authUser"
import { eprescription } from "../../context/AppReducer"
import { validationSchema } from "./utils/eprescriptionFormSchema"
import { useEprescriptionProducts } from "./hooks/useEprescriptionProducts"
import { initialQuantities } from "./EprescriptionForm/utils/addMedicineSchema"

const EprescriptionForm = () => {
  const [loading, setLoading] = useState(false)
  const { dispatch, state } = useContext(AppContext)
  const { medicines } = state?.eprescription
  const prescribedMedicines = useEprescriptionProducts().allProducts.nodes.map(
    medicine => ({
      ...medicine,
      label: medicine.productTitle,
      value: medicine.productTitle,
      quantity: initialQuantities[medicine.productTitle],
    })
  )

  const getIssueDate = () => {
    return {
      month: { label: moment().format("MMM"), value: moment().format("MMM") },
      date: {
        label: parseInt(moment().format("D")),
        value: parseInt(moment().format("D")),
      },
      year: moment().format("YYYY"),
    }
  }

  const handleSubmit = (values, { setErrors }) => {
    setLoading(true)
    const doctorData = getAuthUser()?.userData

    if (medicines?.length < 1) return values
    if (
      !isFutureDate({
        dateObject: {
          ...values?.expirationDate,
          month: values?.expirationDate?.month?.value,
          date: values?.expirationDate?.date?.value,
        },
      })
    ) {
      setErrors({
        expirationDate: {
          month: { value: "Please enter a valid expiration date." },
          date: { value: "Please enter a valid expiration date." },
          year: "Please enter a valid expiration date.",
        },
      })
      return values
    }

    dispatch({
      type: eprescription.SAVE_EPRESCRIPTION,
      payload: {
        medicines,
        indication: values?.indication?.label,
        otherIndication: values?.otherIndication,
        expirationDate: {
          ...values?.expirationDate,
        },
        issueDate: getIssueDate(),
        patientUid: state?.patient?.id,
        doctorUid: doctorData?.id,
        patientInstructions: values?.patientInstructions || "N/A",
        concurrentMedication: values?.concurrentMedication || [],
      },
    })
    navigate("/view")
  }

  useEffect(() => {
    if (!state?.patient?.id) navigate("/patients")
    if (state?.eprescription?.medicines?.length < 1)
      dispatch({
        type: eprescription.SAVE_EPRESCRIPTION,
        payload: {
          medicines: prescribedMedicines,
        },
      })
  }, [state, dispatch, prescribedMedicines])

  return (
    <Fragment>
      <Formik
        initialValues={{
          ...state?.eprescription,
          indication: state?.eprescription?.indication?.value
            ? state?.eprescription?.indication
            : {
                label: state?.eprescription?.indication,
                value: state?.eprescription?.indication,
              },
        }}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({ values, isValid, submitCount }) => {
          let isInvalidExpirationDate = !isFutureDate({
            dateObject: {
              ...values?.expirationDate,
              month: values?.expirationDate?.month?.value,
              date: values?.expirationDate?.date?.value,
            },
          })
          let invalidForm =
            (!isValid || medicines?.length < 1) && submitCount > 0
          let invalidFormMessage =
            medicines?.length < 1
              ? "Your prescribed medicine list cannot be empty."
              : isInvalidExpirationDate
              ? "You have entered an invalid expiration date."
              : "You may have missed some required fields. Please scan through the form and check if your information is complete."

          return (
            <Form>
              <Message color="warning">
                <p className="has-text-black is-size-6">
                  Under the <ProgramName />, each patient will receive one (1)
                  Semaglutide 0.25mg Pre-filled Pen and two (2) Semaglutide
                  0.5mg Pre-filled Pens. For prescriptions in excess of this,
                  your patients will have to wait until they are commercially
                  available to purchase the medicine.
                </p>
              </Message>
              <PatientInformationSection />
              <EprescriptionDetailsSection values={values} />

              {invalidForm && (
                <Message color="danger" className="mb-2">
                  {invalidFormMessage}
                </Message>
              )}
              <Link to="/issue-erx/upload">
                Prefer to upload your own prescription?
              </Link>

              <ActionButtons
                submit={{ label: "Submit", loading, disabled: loading }}
                back={{ label: "Back", link: "/patients/view" }}
              />
            </Form>
          )
        }}
      </Formik>
    </Fragment>
  )
}

export default EprescriptionForm
