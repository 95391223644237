import React, { useEffect } from "react"
import { navigate } from "gatsby"
import { isBrowser } from "services/general"
import { hasAuthUser } from "./services/authUser"

import Layout from "layout/Layout"
import Container from "layout/Container"

const PrivateRoute = ({
  component: Component,
  location,
  title,
  subtitle,
  ...rest
}) => {
  useEffect(() => {
    if (isBrowser() && !hasAuthUser())
      navigate(`/sign-in`, { state: { ...location } })
  }, [location])

  if (isBrowser() && hasAuthUser())
    return (
      <Layout title={title} subtitle={subtitle} {...rest}>
        <Container isCentered {...rest}>
          <Component {...rest} location={location} />
        </Container>
      </Layout>
    )

  return null
}

export default PrivateRoute
