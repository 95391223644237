import { useStaticQuery, graphql } from "gatsby"

const ALL_EPRESCRIPTION_IMAGES = graphql`
  {
    logo: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
      childImageSharp {
        fixed(height: 40) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    meds: file(relativePath: { eq: "icons/icons__meds.png" }) {
      childImageSharp {
        fixed(height: 60) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export const useEprescriptionImages = () => {
  const data = useStaticQuery(ALL_EPRESCRIPTION_IMAGES)

  return data
}
