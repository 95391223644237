import React, { useContext } from "react"
import Img from "gatsby-image"

import PrescribedMedicineRow from "./PrescribedMedicineRow"

import { AppContext } from "../../../context/AppContext"
import { hasDoctorRole } from "../../Auth/services/authUser"
import { useEprescriptionImages } from "../hooks/useEprescriptionImages"

export const PrescribedMedicinesList = ({
  hasMedicinesPrescribed,
  medicines,
}) => {
  const images = useEprescriptionImages()
  const isDoctor = hasDoctorRole()

  if (hasMedicinesPrescribed)
    return medicines?.map((medicine, index) => (
      <PrescribedMedicineRow medicine={medicine} index={index} />
    ))
  return (
    <div className="is-flex is-flex-direction-column is-align-items-center my-2">
      <Img fixed={images?.meds?.childImageSharp?.fixed} />
      <p className="is-size-5 has-text-grey mt-1">
        You have not{!isDoctor && " been"} prescribed any medicines.
      </p>
    </div>
  )
}

const PrescribedMedicinesSection = () => {
  const { state } = useContext(AppContext)
  const { medicines } = state?.eprescription

  const hasMedicinesPrescribed = medicines?.length > 0

  return (
    <div className="mb-2">
      <PrescribedMedicinesList
        hasMedicinesPrescribed={hasMedicinesPrescribed}
        medicines={medicines}
      />
    </div>
  )
}

export default PrescribedMedicinesSection
