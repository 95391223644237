import React, { useContext } from "react"

import Section from "elements/Section"
import { AppContext } from "../../../context/AppContext"

import { getAge } from "services/date"

const PatientInformationSection = () => {
  const { state } = useContext(AppContext)

  return (
    <Section title="Patient Information">
      <p className="has-text-weight-bold">
        {state?.patient?.lastName}, {state?.patient?.firstName}
      </p>
      <p className="is-size-6">
        {state?.patient?.sex} |{" "}
        {getAge({ dateObject: state?.patient?.birthday })} years old
      </p>
    </Section>
  )
}

export default PatientInformationSection
