import React from "react"
import classNames from "classnames"

import RxIndicator from "elements/RxIndicator"

import styles from "../utils/eprescription.module.scss"

const PrescribedMedicineRow = ({ medicine }) => {
  return (
    <div
      className={classNames(styles["eprescription__prescribedMedicine"], "p-1")}
    >
      <p className="is-size-5 has-text-primary is-flex is-justify-content-space-between">
        <span>
          {medicine?.value} <RxIndicator />
        </span>
      </p>
      <p className="is-size-5"> #{medicine.quantity}</p>
    </div>
  )
}

export default PrescribedMedicineRow
