import React from "react"

import Section from "elements/Section"
import FormDate from "elements/Form/FormDate"
import FormInput from "elements/Form/FormInput"
import FormSelect from "elements/Form/FormSelect"
import FormCheckbox from "elements/Form/FormCheckbox"
import FormTextArea from "elements/Form/FormTextArea"
import PrescribedMedicinesSection from "./PrescribedMedicinesSection"

import { indicationOptions } from "./utils/addMedicineSchema"
import { concurrentMedicationsChoices } from "../utils/eprescriptionFormSchema"

const EprescriptionDetailsSection = ({ values }) => {
  return (
    <Section title="ePrescription Details" className="mb-0">
      <PrescribedMedicinesSection />
      <FormSelect
        value={values?.indication}
        label="Indication"
        helper="Why are you prescribing these medicines?"
        options={indicationOptions}
        isRequired
        name="indication"
      />
      {values?.indication?.value === "Other" && (
        <FormInput
          name="otherIndication"
          label="Please indicate your patient's specific condition"
          isRequired
        />
      )}
      <FormCheckbox
        name="concurrentMedication"
        title="Current medication that the patient is taking:"
        helper="Select all that apply"
        options={concurrentMedicationsChoices}
        values={values?.concurrentMedication}
        hideOptional
      />
      <FormDate
        name="expirationDate"
        label="ePrescription Validity"
        values={values?.expirationDate}
        isRequired
      />
      <FormTextArea
        name="patientInstructions"
        label="Patient Instructions"
        isRequired
      />
    </Section>
  )
}

export default EprescriptionDetailsSection
