import * as Yup from "yup"
import { REQUIRED_MESSAGE } from "../../../../services/validations"

export const indicationOptions = [
  {
    label: "Type 2 Diabetes",
    value: "Type 2 Diabetes",
  },
  {
    label: "Other (please specify)",
    value: "Other",
  },
]

export const initialQuantities = {
  "Semaglutide 0.25mg Pre-filled Pen (Ozempic®)": 1,
  "Semaglutide 0.5mg Pre-filled Pen (Ozempic®)": 2,
}

export const initialValues = {
  indication: { value: null },
  quantity: 1,
  dailyIntake: 1,
  otherNotes: "",
}

export const validationSchema = Yup.object().shape({
  dailyIntake: Yup.number()
    .min(1, REQUIRED_MESSAGE)
    .required(REQUIRED_MESSAGE),
  indication: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  quantity: Yup.number().min(1, REQUIRED_MESSAGE),
})
