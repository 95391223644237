import React from "react"
import { Router } from "@reach/router"

import PrivateRoute from "components/Auth/PrivateRoute"
import EprescriptionForm from "components/Eprescription/EprescriptionForm"
import SignIn from "components/Auth/SignIn"

export default () => {
  return (
    <Router>
      <PrivateRoute
        path="/new"
        seoTitle="New ePrescription"
        component={EprescriptionForm}
        title="New ePrescription"
        subtitle=""
        type="new"
      />
      <SignIn path="/sign-in" />
    </Router>
  )
}
