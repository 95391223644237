import * as Yup from "yup"
import {
  yearNow,
  INVALID_YEAR,
  REQUIRED_MESSAGE,
} from "../../../services/validations"

export const concurrentMedicationsChoices = [
  "Metformin/Sulfonylurea",
  "DPP-iv",
  "SGLT-2i",
  "Insulin",
  "GLP-1 RA",
]

export const prescribedMedicineChoices = [
  {
    label: "Semaglutide 0.5mg Pre-filled Pen (Ozempic®)",
    value: "Semaglutide 0.5mg Pre-filled Pen (Ozempic®)",
  },
  {
    label: "Semaglutide 1mg Pre-filled Pen (Ozempic®)",
    value: "Semaglutide 1mg Pre-filled Pen (Ozempic®)",
  },
]

export const initialValues = {
  prescribedMedicine: "",
  expirationDate: { month: { value: null }, date: { value: null }, year: "" },
}

export const validationSchema = Yup.object().shape({
  expirationDate: Yup.object().shape({
    month: Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    date: Yup.object().shape({
      value: Yup.number()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    }),
    year: Yup.number()
      .min(1900, INVALID_YEAR)
      .max(yearNow, INVALID_YEAR)
      .required(REQUIRED_MESSAGE),
  }),
  indication: Yup.object().shape({
    value: Yup.string()
      .required(REQUIRED_MESSAGE)
      .nullable(),
  }),
  otherIndication: Yup.string().when("indication", {
    is: indication => indication?.value === "Other",
    then: Yup.string().required("This field is required"),
  }),
  patientInstructions: Yup.string().required(REQUIRED_MESSAGE),
})
